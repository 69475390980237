const servers =  [
    {
        path: '/servers',
        name: 'servers.index',
        component: () => import('@/pages/Server/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Servers",
        }
    },
    {
        path: '/servers/show/:serverID',
        name: 'servers.show',
        component: () => import('@/pages/Server/ShowServer.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: "Servers view details",
        }
    },
]

export default servers