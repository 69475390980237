const vpc = [
    {
        path: '/:project_id/vpc',
        name: 'vpc.index',
        component: () => import('@/pages/Cloud/VPS/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "VPS sotib olish",
        }
    },
    {
        path: '/:project_id/vpc/vps',
        name: 'vpc.vps.index',
        component: () => import('@/pages/Cloud/VPS/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "VPS sotib olish",
        }
    },
    {
        path: '/:project_id/vpc/volumes',
        name: 'vpc.volumes',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/vpc/networks/:tab',
        name: 'vpc.networks',
        component: () => import('@/pages/Cloud/Network/IndexPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Сеть",
        }
    },
    {
        path: '/:project_id/vpc/firewalls',
        name: 'vpc.firewalls',
        component: () => import('@/pages/Cloud/Firewall/IndexPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/vpc/firewalls/create',
        name: 'vpc.firewalls.create',
        component: () => import('@/pages/Cloud/Firewall/CreatePage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/vpc/firewalls/:id/edit',
        name: 'vpc.firewalls.edit',
        component: () => import('@/pages/Cloud/Firewall/EditPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/vpc/images',
        name: 'vpc.images',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/vpc/disks',
        name: 'vpc.disks',
        component: () => import('@/pages/Cloud/Disk/Index.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Диски",
        }
    },
    {
        path: '/:project_id/vpc/backups',
        name: 'vpc.backups',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/vpc/file-storages',
        name: 'vpc.file_storages',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/vpc/load-balancers',
        name: 'vpc.load_balancers',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    }
]

export default vpc